import React, { useEffect, useState } from 'react'
import { ArticleLayout } from '~/componentsV2/article'

interface Blog {
  id: number
  handle: string
  title: string
  updated_at: string
  commentable: string
  feedburner: string
  feedburner_location: string
  created_at: string
  template_suffix: string
  tags: string
  admin_graphql_api_id: string
}

export interface Article {
  id: number
  title: string
  handle: string
  created_at: string
  updated_at: string
  published_at: string
  template_suffix: string
  blog_id: number
  admin_graphql_api_id: string
  body_html: string
  summary_html?: string
  author: string
  image: {
    src: string
  }
  tags?: string
}

interface ArticleData {
  article: Article
}

export const BlogPreviewPage = ({ params }: { params: { blog: string; article: string } }) => {
  const [articleData, setArticleData] = useState<Article | null>(null)
  const [errors, setErrors] = useState<string | null>(null)
  const { blog, article } = params

  const apiUrl =
    process.env.GATSBY_PROXY_URL +
    '/shopify-proxy/https://bensoleimani.myshopify.com/admin/api/2024-07/'

  const fetchBlogs = async (): Promise<Record<string, number>> => {
    const response = await fetch(`${apiUrl}/blogs.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const data = await response.json()
    const blogs: Record<string, number> = {}
    data.blogs.forEach((blog: Blog) => {
      blogs[blog.handle] = blog.id
    })
    return blogs
  }

  const fetchArticle = async (blogId: number, articleHandle: string) => {
    const response = await fetch(`${apiUrl}/blogs/${blogId}/articles/${articleHandle}.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    const returnData = await response.json()

    if (returnData.errors) {
      setErrors("Article doesn't exist. Please try another article.")

      return
    }

    setArticleData(returnData.article as Article)
  }

  useEffect(() => {
    const loadBlogAndArticle = async () => {
      try {
        const blogs = await fetchBlogs()
        const blogId = blogs[blog]

        if (blogId && article) {
          await fetchArticle(blogId, article)
        }
      } catch (error) {
        console.error('Error fetching blog or article data:', error)
      }
    }

    loadBlogAndArticle()
  }, [blog, article])

  if (errors) {
    return (
      <div>
        <p>{errors}</p>
      </div>
    )
  }

  return (
    <div>
      {articleData ? (
        <ArticleLayout
          noIndex={true}
          data={{
            title: articleData.title,
            excerpt: articleData?.summary_html || '',
            image: {
              url: articleData.image.src,
            },
            contentHtml: articleData.body_html,
            tags: articleData.tags?.split(',').map(tag => tag.trim()) || [],
            handle: articleData.handle,
            firstTag: articleData?.tags ? articleData.tags.split(',')[0] : '',
            author: {
              name: articleData.author,
              bio: '',
              email: '',
            },
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default BlogPreviewPage
